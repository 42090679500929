var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索条件" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("创建")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "border·": "",
            stripe: "",
            "default-expand-all": "",
            "row-key": "ID",
            "tree-props": { children: "children", hasChildren: true },
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { label: "ID", prop: "ID" } }),
          _c("el-table-column", {
            attrs: { label: "平台名称", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "推广类型", prop: "promotionType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.filterDict(
                            scope.row.promotionType,
                            "promotion_type"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "订单类型", prop: "canList" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatBoolean")(scope.row.canList)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "支持分销", prop: "supportFanli" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("formatBoolean")(scope.row.supportFanli))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "支持推广", prop: "support_prom" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("formatBoolean")(scope.row.support_prom))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "支持开放平台", prop: "isOpen" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatBoolean")(scope.row.isOpen)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.parentId > 0
                      ? [
                          _c(
                            "el-button-group",
                            [
                              _c("el-button", {
                                staticClass: "table-button",
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  icon: "el-icon-edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updatePlatform(scope.row)
                                  }
                                }
                              }),
                              _c("el-button", {
                                staticClass: "table-button",
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  icon: "el-icon-info"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updatePlatformInfo(scope.row)
                                  }
                                }
                              }),
                              _c("el-button", {
                                staticClass: "table-button",
                                attrs: {
                                  size: "small",
                                  type: "success",
                                  icon: "el-icon-refresh"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSyncLink(scope.row)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "创建/编辑平台信息",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "platformForm",
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "140px",
                rules: _vm.platformRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台ID", prop: "ID" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: "请输入平台ID",
                      maxlength: 11,
                      "show-word-limit": "",
                      clearable: "",
                      "prefix-icon": "el-icon-mobile"
                    },
                    model: {
                      value: _vm.formData.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "ID", _vm._n($$v))
                      },
                      expression: "formData.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级", prop: "parentId" } },
                [
                  _c("platform-select", {
                    attrs: {
                      platformId: _vm.formData.parentId,
                      mode: 0,
                      placeholder: "请选择平台"
                    },
                    on: {
                      "update:platformId": function($event) {
                        return _vm.$set(_vm.formData, "parentId", $event)
                      },
                      "update:platform-id": function($event) {
                        return _vm.$set(_vm.formData, "parentId", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台组", prop: "belongType" } },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "100%" },
                      attrs: { placeholder: "请选择平台组", clearable: "" },
                      model: {
                        value: _vm.formData.belongType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "belongType", $$v)
                        },
                        expression: "formData.belongType"
                      }
                    },
                    _vm._l(_vm.belong_typeOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台名称", prop: "name" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      placeholder: "请输入平台名称",
                      maxlength: 11,
                      "show-word-limit": "",
                      clearable: "",
                      "prefix-icon": "el-icon-mobile"
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台logo:", prop: "logo" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.formData.logo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "logo", $$v)
                      },
                      expression: "formData.logo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "微信背景图（淘系专用）:",
                    prop: "wxBackground"
                  }
                },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.formData.wxBackground,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "wxBackground", $$v)
                      },
                      expression: "formData.wxBackground"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推广类型", prop: "promotion_type" } },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "100%" },
                      attrs: { placeholder: "请输入推广类型", clearable: "" },
                      model: {
                        value: _vm.formData.promotionType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "promotionType", $$v)
                        },
                        expression: "formData.promotionType"
                      }
                    },
                    _vm._l(_vm.promotion_typeOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支持分销配置",
                    prop: "supportFanli",
                    required: ""
                  }
                },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "开启", "inactive-text": "关闭" },
                    model: {
                      value: _vm.formData.supportFanli,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "supportFanli", $$v)
                      },
                      expression: "formData.supportFanli"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "支持推广", prop: "support_prom" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "开启", "inactive-text": "关闭" },
                    model: {
                      value: _vm.formData.support_prom,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "support_prom", $$v)
                      },
                      expression: "formData.support_prom"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "支持开放平台", prop: "isOpen", required: "" }
                },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "开启", "inactive-text": "关闭" },
                    model: {
                      value: _vm.formData.isOpen,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "isOpen", $$v)
                      },
                      expression: "formData.isOpen"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "展示订单/订单同步",
                    prop: "canList",
                    required: ""
                  }
                },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "开启", "inactive-text": "关闭" },
                    model: {
                      value: _vm.formData.canList,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "canList", $$v)
                      },
                      expression: "formData.canList"
                    }
                  })
                ],
                1
              ),
              _vm.formData.supportFanli == 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单归属平台ID",
                        prop: "orderPlatformId"
                      }
                    },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "请输入订单归属平台",
                          clearable: ""
                        },
                        model: {
                          value: _vm.formData.orderPlatformId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "orderPlatformId",
                              _vm._n($$v)
                            )
                          },
                          expression: "formData.orderPlatformId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.promotionType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "推广参数", prop: "promotion_code" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入推广参数", clearable: "" },
                        model: {
                          value: _vm.formData.promotionCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "promotionCode", $$v)
                          },
                          expression: "formData.promotionCode"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "推广链接", prop: "promotion_url" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "请输入推广链接",
                          clearable: "",
                          type: "textarea",
                          rows: 5
                        },
                        model: {
                          value: _vm.formData.promotionUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "promotionUrl", $$v)
                          },
                          expression: "formData.promotionUrl"
                        }
                      }),
                      _vm._v(" 占位符支持: "),
                      _c("span", { staticClass: "tag" }, [_vm._v("[sid]")]),
                      _c("span", { staticClass: "tag" }, [_vm._v("[phone]")]),
                      _c("span", { staticClass: "tag" }, [_vm._v("[code]")]),
                      _c("span", { staticClass: "tag" }, [_vm._v("[app_key]")])
                    ],
                    1
                  ),
              _c("el-divider", [_vm._v("微信小程序配置")]),
              _c(
                "el-form-item",
                { attrs: { label: "APPID" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "小程序APPID", clearable: "" },
                    model: {
                      value: _vm.formData.WxMp.app_id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData.WxMp, "app_id", $$v)
                      },
                      expression: "formData.WxMp.app_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原始ID" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "小程序原始ID", clearable: "" },
                    model: {
                      value: _vm.formData.WxMp.org_id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData.WxMp, "org_id", $$v)
                      },
                      expression: "formData.WxMp.org_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PATH" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "小程序路径", clearable: "" },
                    model: {
                      value: _vm.formData.WxMp.path,
                      callback: function($$v) {
                        _vm.$set(_vm.formData.WxMp, "path", $$v)
                      },
                      expression: "formData.WxMp.path"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closePlatformInfoDialog,
            visible: _vm.dialogPlatformInfoVisible,
            title: _vm.type == "update" ? "编辑信息" : "创建信息",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPlatformInfoVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "platformInfoForm",
              attrs: {
                model: _vm.platformInfo,
                "label-position": "right",
                "label-width": "120px",
                rules: _vm.platformInfoRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台ID:", prop: "platformId" } },
                [
                  _c("platform-select", {
                    attrs: {
                      platformId: _vm.platformInfo.platformId,
                      mode: 2,
                      placeholder: "请选择平台"
                    },
                    on: {
                      "update:platformId": function($event) {
                        return _vm.$set(_vm.platformInfo, "platformId", $event)
                      },
                      "update:platform-id": function($event) {
                        return _vm.$set(_vm.platformInfo, "platformId", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题:", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.platformInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "title", $$v)
                      },
                      expression: "platformInfo.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "领取文案:",
                            prop: "buttonText.button1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.platformInfo.buttonText.button1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.buttonText,
                                  "button1",
                                  $$v
                                )
                              },
                              expression: "platformInfo.buttonText.button1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分享文案:",
                            prop: "buttonText.button2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.platformInfo.buttonText.button2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.buttonText,
                                  "button2",
                                  $$v
                                )
                              },
                              expression: "platformInfo.buttonText.button2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片:", prop: "cover" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.platformInfo.cover,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "cover", $$v)
                      },
                      expression: "platformInfo.cover"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注意事项:", prop: "notify" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.platformInfo.notify,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "notify", $$v)
                      },
                      expression: "platformInfo.notify"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "红包面值:", prop: "maxRedPacket" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.platformInfo.maxRedPacket,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo,
                                  "maxRedPacket",
                                  _vm._n($$v)
                                )
                              },
                              expression: "platformInfo.maxRedPacket"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "按钮颜色:", prop: "buttonColor" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.platformInfo.buttonColor[0],
                              callback: function($$v) {
                                _vm.$set(_vm.platformInfo.buttonColor, 0, $$v)
                              },
                              expression: "platformInfo.buttonColor[0]"
                            }
                          }),
                          _c("el-color-picker", {
                            model: {
                              value: _vm.platformInfo.buttonColor[1],
                              callback: function($$v) {
                                _vm.$set(_vm.platformInfo.buttonColor, 1, $$v)
                              },
                              expression: "platformInfo.buttonColor[1]"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色:", prop: "backgroundColor" } },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.platformInfo.backgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "backgroundColor", $$v)
                      },
                      expression: "platformInfo.backgroundColor"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "流程图:" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "返利H5", "label-width": "20" } },
                        [
                          _c("upload-image", {
                            attrs: { fileSize: 1024, maxWH: 1080 },
                            model: {
                              value: _vm.platformInfo.processPicObj.fl_h5,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.processPicObj,
                                  "fl_h5",
                                  $$v
                                )
                              },
                              expression: "platformInfo.processPicObj.fl_h5"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "无返利H5", "label-width": "20" } },
                        [
                          _c("upload-image", {
                            attrs: { fileSize: 1024, maxWH: 1080 },
                            model: {
                              value: _vm.platformInfo.processPicObj.nfl_h5,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.processPicObj,
                                  "nfl_h5",
                                  $$v
                                )
                              },
                              expression: "platformInfo.processPicObj.nfl_h5"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "返利小程序", "label-width": "20" } },
                        [
                          _c("upload-image", {
                            attrs: { fileSize: 1024, maxWH: 1080 },
                            model: {
                              value: _vm.platformInfo.processPicObj.fl_mp,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.processPicObj,
                                  "fl_mp",
                                  $$v
                                )
                              },
                              expression: "platformInfo.processPicObj.fl_mp"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "无返利小程序", "label-width": "20" }
                        },
                        [
                          _c("upload-image", {
                            attrs: { fileSize: 1024, maxWH: 1080 },
                            model: {
                              value: _vm.platformInfo.processPicObj.nfl_mp,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.processPicObj,
                                  "nfl_mp",
                                  $$v
                                )
                              },
                              expression: "platformInfo.processPicObj.nfl_mp"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享图:", prop: "shareBanner" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.platformInfo.shareBanner,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "shareBanner", $$v)
                      },
                      expression: "platformInfo.shareBanner"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报底图:", prop: "sharePoster" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.platformInfo.sharePoster,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "sharePoster", $$v)
                      },
                      expression: "platformInfo.sharePoster"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台logo:", prop: "icon" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.platformInfo.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "icon", $$v)
                      },
                      expression: "platformInfo.icon"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享文案:", prop: "shareText" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入分享文案 支持占位符[TKL] [URL]",
                      rows: 5
                    },
                    model: {
                      value: _vm.platformInfo.shareText,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "shareText", $$v)
                      },
                      expression: "platformInfo.shareText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享页注意事项:", prop: "shareNotify" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.platformInfo.shareNotify,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "shareNotify", $$v)
                      },
                      expression: "platformInfo.shareNotify"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享卡片文案:", prop: "shareCardTitle" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.platformInfo.shareCardTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "shareCardTitle", $$v)
                      },
                      expression: "platformInfo.shareCardTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享卡片图片:", prop: "shareCardPic" } },
                [
                  _c("upload-image", {
                    attrs: { fileSize: 1024, maxWH: 1080 },
                    model: {
                      value: _vm.platformInfo.shareCardPic,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "shareCardPic", $$v)
                      },
                      expression: "platformInfo.shareCardPic"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否发送客服消息",
                    prop: "xcxMessage.status"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.platformInfo.xcxMessage.status,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo.xcxMessage, "status", $$v)
                      },
                      expression: "platformInfo.xcxMessage.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "佣金比", prop: "commissionRatio" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      precision: 2,
                      step: 0.1,
                      clearable: "",
                      placeholder: "请输入佣金比例"
                    },
                    model: {
                      value: _vm.platformInfo.commissionRatio,
                      callback: function($$v) {
                        _vm.$set(_vm.platformInfo, "commissionRatio", $$v)
                      },
                      expression: "platformInfo.commissionRatio"
                    }
                  })
                ],
                1
              ),
              _vm.platformInfo.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息标题",
                            prop: "xcxMessage.title"
                          }
                        },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入客服消息标题",
                              clearable: ""
                            },
                            model: {
                              value: _vm.platformInfo.xcxMessage.title,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.xcxMessage,
                                  "title",
                                  $$v
                                )
                              },
                              expression: "platformInfo.xcxMessage.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.platformInfo.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息图片",
                            prop: "xcxMessage.image"
                          }
                        },
                        [
                          _c("upload-image", {
                            attrs: { fileSize: 1024, maxWH: 1080 },
                            model: {
                              value: _vm.platformInfo.xcxMessage.image,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.xcxMessage,
                                  "image",
                                  $$v
                                )
                              },
                              expression: "platformInfo.xcxMessage.image"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.platformInfo.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息路径",
                            prop: "xcxMessage.path"
                          }
                        },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请选择客服消息路径",
                              clearable: ""
                            },
                            model: {
                              value: _vm.platformInfo.xcxMessage.path,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.platformInfo.xcxMessage,
                                  "path",
                                  $$v
                                )
                              },
                              expression: "platformInfo.xcxMessage.path"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closePlatformInfoDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.enterPlatformInfoDialog }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }