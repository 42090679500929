var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            action: _vm.path + "/fileUploadAndDownload/upload",
            "show-file-list": false,
            "on-success": _vm.handleImageSuccess,
            "before-upload": _vm.beforeImageUpload,
            multiple: false
          }
        },
        [
          _vm.imageUrl
            ? _c("img", { staticClass: "image", attrs: { src: _vm.imageUrl } })
            : _c("i", { staticClass: "el-icon-plus image-uploader-icon" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }