import service from '@/utils/request'; // @Tags Platform
// @Summary 创建Platform
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Platform true "创建Platform"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /platform/createPlatform [post]

export var createPlatform = function createPlatform(data) {
  return service({
    url: "/platform/createPlatform",
    method: 'post',
    data: data
  });
}; // @Tags Platform
// @Summary 删除Platform
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Platform true "删除Platform"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /platform/deletePlatform [delete]

export var deletePlatform = function deletePlatform(data) {
  return service({
    url: "/platform/deletePlatform",
    method: 'delete',
    data: data
  });
}; // @Tags Platform
// @Summary 删除Platform
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除Platform"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /platform/deletePlatform [delete]

export var deletePlatformByIds = function deletePlatformByIds(data) {
  return service({
    url: "/platform/deletePlatformByIds",
    method: 'delete',
    data: data
  });
}; // @Tags Platform
// @Summary 更新Platform
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Platform true "更新Platform"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /platform/updatePlatform [put]

export var updatePlatform = function updatePlatform(data) {
  return service({
    url: "/platform/updatePlatform",
    method: 'put',
    data: data
  });
}; // @Tags Platform
// @Summary 用id查询Platform
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Platform true "用id查询Platform"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /platform/findPlatform [get]

export var findPlatform = function findPlatform(params) {
  return service({
    url: "/platform/findPlatform",
    method: 'get',
    params: params
  });
}; // @Tags Platform
// @Summary 分页获取Platform列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Platform列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /platform/getPlatformList [get]

export var getPlatformList = function getPlatformList(params) {
  return service({
    url: "/platform/getPlatformList",
    method: 'get',
    params: params
  });
}; // @Tags Platform
// @Summary 分页获取Platform列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Platform列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /platform/getPlatformList [get]

export var getPlatformTree = function getPlatformTree(params) {
  return service({
    url: "/platform/getPlatformTree",
    method: 'get',
    params: params
  });
}; // @Tags Platforms
// @Summary 平台下拉框数据
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /platform/getPlatformSelection [get]

export var getPlatformSelection = function getPlatformSelection(params) {
  return service({
    url: "/platform/getPlatformSelection",
    method: 'get',
    params: params
  });
};
export var getPlatformPromotion = function getPlatformPromotion(params) {
  return service({
    url: "/platform/getPromotionData",
    method: 'get',
    params: params
  });
};
export var doSycnLink = function doSycnLink(data) {
  return service({
    url: "/platform/doSycnLink",
    method: 'post',
    data: data
  });
};