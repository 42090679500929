import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var path = process.env.VUE_APP_BASE_API;
import ImageCompress from "@/utils/image.js";
export default {
  name: "upload-image",
  model: {
    prop: "imageUrl",
    event: "change"
  },
  props: {
    imageUrl: {
      type: String,
      default: ""
    },
    fileSize: {
      type: Number,
      default: 2048 // 2M 超出后执行压缩

    },
    maxWH: {
      type: Number,
      default: 1920 // 图片长宽上限

    }
  },
  data: function data() {
    return {
      path: path
    };
  },
  methods: {
    beforeImageUpload: function beforeImageUpload(file) {
      var isRightSize = file.size / 1024 < this.fileSize;

      if (!isRightSize) {
        // 压缩
        var compress = new ImageCompress(file, this.fileSize, this.maxWH);
        return compress.compress();
      }

      return isRightSize;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      var data = res.data;

      if (data.file) {
        this.$emit("change", data.file.url);
      }
    }
  }
};